






























































































































































































































































































































































































import {Component, Watch} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {ReportPermission} from '~/utils/permissions'
import {ApexOptions} from 'apexcharts'
import {mixins} from 'vue-class-component'
import LoadingMixin from '~/mixins/loading-mixin'
import {SubjectList} from '@afterschool.dev/as-data-admin'
import DatePickerShortcutMixin from '~/mixins/date-picker-shortcut-mixin'
import moment from 'moment'

class BOverview {
    net_revenue = {
        paid: 0,
        trial: 0
    };
    gross_revenue = {
        paid: 0,
        trial: 0
    };
    refund_amount = 0;
    orders = {
        paid: 0,
        trial: 0
    };
    students = {
        total: 0,
        paid: 0,
        trial: 0,
        free: 0
    };
    gross_average = {
        per_order: 0,
        per_paid_student: 0
    };
    diamond_used = 0;
}

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Business Performance'
        }
    }
})
export default class BusinessDashboard extends mixins(LoadingMixin, DatePickerShortcutMixin) {
    static permission = ReportPermission.BusinessPerformance

    // region start - end range
    dateRange: number[] = []
    startDate = 0
    endDate = 0

    get pickerOptions(): { shortcuts: Array<{ text: string, onClick: {} }> } {
        return {
            shortcuts: this.datePickerShortcuts
        }
    }

    // endregion

    // region course type
    courseOptions = 'all'
    AllCourse = 'all'
    PaidCourse = 'paid'
    TrialCourse = 'trial'
    // endregion

    // region overview

    showMoreOverview: boolean = false

    reviewPaid = 0
    reviewTrial = 0
    ordersPaid = 0
    ordersTrial = 0
    studentTotal = 0
    studentPaid = 0
    studentTrial = 0
    studentFree = 0
    averagePerOrder = 0
    averagePerPaidStudent = 0
    diamondUsed = 0

    overviewDataRaw: BOverview = new BOverview()

    get overview(): string[][] {
        function sum(obj: {paid: number, trial: number}) {
            return obj.paid + obj.trial
        }
        return [
            ['NET REVENUE', '$ ' + sum(this.overviewDataRaw.net_revenue)],
            ['GROSS REVENUE', '$ ' + sum(this.overviewDataRaw.gross_revenue)],
            ['# OF ORDERS', String(sum(this.overviewDataRaw.orders))],
            ['# OF STUDENTS', String(this.overviewDataRaw.students.total)],
        ]
    }

    get moreOverview() {
        return [
            [
                ["PAID", "$" + this.overviewDataRaw.net_revenue.paid],
                ["TRIAL", "$" + this.overviewDataRaw.net_revenue.trial]
            ], // net
            [
                ["PAID", "$" + this.overviewDataRaw.gross_revenue.paid],
                ["TRIAL", "$" + this.overviewDataRaw.gross_revenue.trial]
            ], // gross
            [
                ["PAID", "$" + this.overviewDataRaw.orders.paid],
                ["TRIAL", "$" + this.overviewDataRaw.orders.trial]
            ], // orders
            [
                ["PAID", "$" + this.overviewDataRaw.students.paid],
                ["TRIAL", "$" + this.overviewDataRaw.students.trial],
                ["FREE", "$" + this.overviewDataRaw.students.free]
            ], // students
        ]
    }

    get overview2(): string[][] {
        return [
            ['DIAMOND USED', '$ ' + this.overviewDataRaw.diamond_used],
            ['REFUND AMOUNT', '$ ' + this.overviewDataRaw.refund_amount],
            ['AVERAGE ORDER GROSS', '$ ' + this.overviewDataRaw.gross_average.per_order.toFixed(3)],
            ['AVERAGE PAID STUDENT GROSS', '$ ' + this.overviewDataRaw.gross_average.per_paid_student.toFixed(3)],
        ]
    }
    // endregion

    // region Revenue
    revenueChartsArray: { title: string, options: ApexOptions, series: { name: string, data: number[] }[] }[] = []
    // endregion

    // region Application Source To Paid Course
    applicationSourceSwitch: boolean = false

    applicationSource: {
        tag: string,
        timestamp: number,
        trial: number,
        trial_count: number,
        organic: number,
        organic_count: number
    }[] = []

    applicationSourceTitle() {
        return this.applicationSource.map((s) => s.tag)
    }

    get applicationSourceTable() {
        return [
            {
                title: 'Trial',
                data: this.applicationSource.map((s) => this.applicationSourceSwitch ? s.trial_count : s.trial)
            },
            {
                title: 'Organic',
                data: this.applicationSource.map((s) => this.applicationSourceSwitch ? s.organic_count : s.organic)
            },
            {
                title: 'Total',
                data: this.applicationSource.map((s) => this.applicationSourceSwitch ? (s.trial_count + s.organic_count) : (s.trial + s.organic))
            }
        ]
    }

    applicationSourceData() {
        let data: {}[] = []
        let trial: number[] = []
        let organic: number[] = []

        this.applicationSource.forEach(s => {
            trial.push(this.applicationSourceSwitch ? s.trial_count : s.trial)
            organic.push(this.applicationSourceSwitch ? s.organic_count : s.organic)
        })

        data.push({name: 'Organic', data: organic})
        data.push({name: 'Trial', data: trial})

        return data
    }

    applicationSourceConfig() {
        let config: ApexOptions = {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%'
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: []
            },
            yaxis: {
                labels: {
                    formatter: (value) => {
                        return this.applicationSourceSwitch ? String(value) : '$' + value
                    },
                    minWidth: 70,
                    maxWidth: 70,
                },
            },
            fill: {
                opacity: 1
            }
        }

        let title: string[] = []
        this.applicationSource.forEach(s => {
            title.push(s.tag)
        })

        config.xaxis!!.categories = title

        return config
    }

    // endregion

    // region default config
    defaultPieChartConfig(): ApexOptions {
        return {
            labels: []
        }
    }

    defaultRevenueConfig(size: number = 0): ApexOptions {
        return {
            chart: {
                type: 'bar',
                height: size ? size * 40 : 350
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '50%'
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                // min: 6,
                // max: 6,
                categories: [],
                labels: {
                    formatter: (value) => {
                        return '$' + value
                    },
                },
            },
            tooltip: {
                y: {
                    formatter: (val: number, opts?: any): string => {
                        return '$' + val
                    }
                }
            }
        }

    }

    defaultBarChartConfig(): ApexOptions {
        return {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%'
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: []
            },
            fill: {
                opacity: 1
            }
        }
    }

    defaultAreaBarChartConfig(): ApexOptions {
        return {
            chart: {
                toolbar: {
                    show: false
                }
            },
            colors: ['#00E396'],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'category',
                categories: []
            },
            yaxis: {
                labels: {
                    formatter: (val: number, opts?: any): string => {
                        return this.timelineSwitch ? String(val) : '$' + val
                    }
                }
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy'
                },
                y: {
                    formatter: (val: number, opts?: any): string => {
                        return this.timelineSwitch ? String(val) : '$' + val
                    }
                }
            },
        }
    }

    // endregion

    // region User Profile
    userProfileDseYrSeries: number [] = []
    userProfileBandingSeries: number[] = []
    userProfileDseYrPieChartConfig = this.defaultPieChartConfig()
    userProfileBandingPieCharConfig = this.defaultPieChartConfig()
    // endregion

    // region distribution
    paymentMethodSeries: number[] = []
    paymentMethodRevenueSeries: number[] = []
    paymentMethodPieCharConfig = this.defaultPieChartConfig()

    salesDevicesSeries: number [] = []
    salesDevicesPieCharConfig = this.defaultPieChartConfig()

    // endregion

    // region timeline
    timelineSwitch = false
    timelineUnit: 'day' | 'week' | 'month' = 'day'

    timeLineRes: any
    timeLineCountData: Array<{ name: string, data: number[] }> = []
    timeLineRevenueData: Array<{ name: string, data: number[] }> = []
    timeLineConfig = this.defaultAreaBarChartConfig()
    timeLineConfigBackup = this.defaultAreaBarChartConfig() // Backup the chart data for export, because apexchart will clear it.

    // endregion

    // region tutor comparison
    tutorComparisonTableData: Array<{
        title: string,
        all: number,
        own: number,
        other: number,
        bold: boolean,
        price: boolean
    }> = []

    // endregion

    created() {
        const date = new Date()
        const start = new Date(date.getFullYear(), date.getMonth(), 1)
        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        this.startDate = start.getTime()
        this.endDate = end.getTime() + 24 * 60 * 60 * 1000 - 1
        this.dateRange[0] = this.startDate
        this.dateRange[1] = this.endDate
        this.getBusinessDashboard()
    }

    datePickerChange() {
        this.startDate = this.dateRange[0]
        this.endDate = this.dateRange[1] + 24 * 60 * 60 * 1000 - 1
        // console.log('startDate : '+this.dateRange[0] + ' ; ' + new Date(this.dateRange[0]).getMonth()+ ' - '+new Date(this.dateRange[0]).getDate())
        // console.log('endDate : '+this.dateRange[1] + ' ; ' + new Date(this.dateRange[1]).getMonth() + ' - '+new Date(this.dateRange[1]).getDate())
        // console.log('startDate : '+this.startDate + ' ; ' + new Date(this.startDate).getMonth()+ ' - '+new Date(this.startDate).getDate())
        // console.log(''ndDate : '+this.endDate + ' ; ' + new Date(this.endDate).getMonth() + ' - '+new Date(this.endDate).getDate())
        this.getBusinessDashboard()
    }

    getBusinessDashboard() {
        this.startLoading('body')
        const param: { [key: string]: any } = {}
        if (this.startDate != 0) {
            param.start = this.startDate
        }
        if (this.endDate != 0) {
            param.end = this.endDate
        }

        param.course_type = this.courseOptions

        createRequest(
            '/courses/dashboard/business',
            'get',
            param
        ).send().then(async (res) => {
            this.overviewDataRaw = res.data.overview
            this.revenueChartsArray = this.revenueData(res.data.revenue)
            this.applicationSource = res.data.application_source || []
            this.userProfileData(res.data.user_profile)
            this.distributionData(res.data.distribution)
            this.timeLineRes = res.data.timeline
            this.timelineData(res.data.timeline)
            this.tutorComparisonData(res.data.tutor_comparison)
        }).finally(() => {
            this.stopLoading('body')
        })
    }

    revenueData(revenue) {
        const titles = ['TUTOR', 'SUBJECT', 'COURSE', 'COURSE SERIES', 'DSE YEAR', 'BANDING']
        return ['tutors', 'subjects', 'courses', 'series', 'dse_year', 'banding'].map((category, i) => {
            let dataAndLabels = this.pushResToDataAndLabels(revenue[category])
            if (category === 'subjects') {
                dataAndLabels.labels = dataAndLabels.labels.map(label => SubjectList.offeredEname[label].tag)
            }
            return {
                title: (category === 'tutors'? 'GROSS ' : '') + 'REVENUE BY ' + titles[i],
                options: this.getRevenueConfigWithLabels(dataAndLabels.labels),
                series: [
                    {
                        name: 'Revenue',
                        data: dataAndLabels.data
                    }
                ]
            }
        })
    }

    userProfileData(userProfile) {
        let resUserProfileDseData = this.pushResToDataAndLabels(userProfile.dse)
        this.userProfileDseYrSeries = resUserProfileDseData.data
        this.userProfileDseYrPieChartConfig = this.getPieConfigWithLabels(resUserProfileDseData.labels)

        let resUserProfileBandingData = this.pushResToDataAndLabels(userProfile.banding)
        this.userProfileBandingSeries = resUserProfileBandingData.data
        this.userProfileBandingPieCharConfig = this.getPieConfigWithLabels(resUserProfileBandingData.labels)
    }

    distributionData(distribution) {
        let resPaymentMethodData = this.pushResToDataAndLabels(distribution.payment_method)
        this.paymentMethodSeries = resPaymentMethodData.data
        this.paymentMethodPieCharConfig = this.getPieConfigWithLabels(resPaymentMethodData.labels)

        let resPaymentMethodRevenueData = this.pushResToDataAndLabels(distribution.payment_method_revenue)
        this.paymentMethodRevenueSeries = resPaymentMethodRevenueData.data

        let resDevicesData = this.pushResToDataAndLabels(distribution.device)
        this.salesDevicesSeries = resDevicesData.data
        this.salesDevicesPieCharConfig = this.getPieConfigWithLabels(resDevicesData.labels)
    }

    @Watch('timelineUnit')
    timelineUnitChanged() {
        this.timelineData(this.timeLineRes)
    }

    timelineData(timeline) {
        let resTimeLineData = this.getTimeLineData(timeline)
        this.timeLineCountData = [{name: 'Count', data: resTimeLineData.count}]
        this.timeLineRevenueData = [{name: 'Sales', data: resTimeLineData.revenue}]
        this.timeLineConfig = this.getAreaChartConfigWithData(resTimeLineData.labels)
        this.timeLineConfigBackup = this.getAreaChartConfigWithData(resTimeLineData.labels)
    }

    tutorComparisonData(tutorComparison) {

        this.tutorComparisonTableData = []

        const tableStructure = {
            'revenue': {
                titles: ['Total Revenue', '  Paid', '  Trial'],
                valueKeys: ['total', 'paid', 'trial']
            },
            'users': {
                titles: ['# of Total Active User', '  # of Paid Course Users', '  # of Trial Course Users', '  # of Free Course Users'],
                valueKeys: ['total', 'paid', 'trial', 'free']
            },
            'orders': {
                titles: ['# of Orders', '  # of Paid Course Orders', '  # of Trial Course Orders', '  # of Free Course Orders'],
                valueKeys: ['total', 'paid', 'trial', 'free']
            }
        }
        for (const section of ['revenue', 'users', 'orders']) {
            let sectionData = tutorComparison[section]
            for (let i = 0; i < tableStructure[section].titles.length; i++) {
                const valueKey = tableStructure[section].valueKeys[i]
                this.tutorComparisonTableData.push({
                    title: tableStructure[section].titles[i],
                    all: sectionData[valueKey].all,
                    own: sectionData[valueKey].own,
                    other: sectionData[valueKey].other,
                    bold: (i == 0),
                    price: (section === 'revenue')
                })
            }
        }
    }

    pushResToDataAndLabels(res: any[]): { data: number[], labels: string[] } {
        return {
            data: res.map((d) => d.value),
            labels: res.map((d) => d.tag)
        }
    }

    getPieConfigWithLabels(labels: string[]) {
        let c = this.defaultPieChartConfig()
        labels.forEach(l => {
            c.labels!!.push(l)
        })
        return c
    }

    getRevenueConfigWithLabels(labels: string[]) {
        let c = this.defaultRevenueConfig(labels.length)
        c.xaxis!!.categories = labels
        return c
    }

    getAreaChartConfigWithData(labels: string[]) {
        let c = this.defaultAreaBarChartConfig()
        c.xaxis!!.categories = labels
        return c
    }

    get timelineEndTimes() {
        const times: number[] = []
        const m = moment(this.startDate)
        m.endOf(this.timelineUnit)
        while (m.valueOf() <= this.endDate) {
            times.push(m.valueOf())
            m.add(1, this.timelineUnit)
        }
        return times
    }

    getTimeLineData(res: any[]): { count: number[], revenue: number[], labels: string[] } {
        const count: number[] = []
        const revenue: number[] = []
        const labels: string[] = []

        switch (this.timelineUnit) {
            case 'day':
                res.forEach(o => {
                    count.push(o.count)
                    revenue.push(o.revenue)
                    labels.push(o.tag)
                })
                break

            case 'month':
            case 'week':
                let c = 0, r = 0
                let endI = 0
                let endTime = this.timelineEndTimes[endI]

                const label = (endTime: number) => {
                    let label = ''
                    if (this.timelineUnit === 'month') {
                        label = moment(endTime).format('MM/YYYY')
                    } else {
                        label = moment(endTime).startOf('week').format('DD/MM') +
                            ' - ' + moment(endTime).format('DD/MM')
                    }
                    return label
                }

                res.forEach(o => {
                    if (o.timestamp > endTime) {
                        count.push(c)
                        revenue.push(r)
                        labels.push(label(endTime))

                        c = 0
                        r = 0
                        endTime = this.timelineEndTimes[++endI]
                    }
                    c += o.count
                    r += o.revenue
                })
                while(endI < this.timelineEndTimes.length) {
                    count.push(c)
                    revenue.push(r)
                    labels.push(label(endTime))
                    endTime = this.timelineEndTimes[++endI]

                    c = 0
                    r = 0
                }
        }

        return {count, revenue, labels}
    }

    tableRowStyle({row, rowIndex}) {
        if (this.tutorComparisonTableData[rowIndex].bold) {
            let style = 'border-top: solid '
            if (rowIndex === 0) {
                style = style + '1px'
            } else {
                style = style + '4px'
            }
            style = style + '#EDEDED;'
            return style
        } else {
            return 'background-color: #fff'
        }
    }

    tableHeaderColor({row, column, rowIndex, columnIndex}) {
        if (rowIndex === 0) {
            return 'background-color: #EDEDED; color: #000; font-weight: 500;'
        }
    }

    get overviewExportContent(): string {
        let overviewString = ''
        const overview = this.overview
        const moreOverview = this.moreOverview
        for (let i = 0; i < overview.length - 1; i++) {
            let rows: string[] = []
            const basicData = overview[i]
            rows.push(basicData[0].replace('#', 'NO.'))
            rows.push('COURSE TYPE,' + basicData[0].replace('#', 'NO.'))
            for (const moreData of moreOverview[i]) {
                rows.push(moreData[0] + ',' + moreData[1].replace('$', ''))
            }
            rows.push('TOTAL,' + basicData[1].replace('$', ''))
            overviewString += rows.join('\n') + '\n\n'
        }
        overviewString += overview[overview.length - 1][0] + ',' + overview[overview.length - 1][1].replace('$', '') + '\n'
        overviewString += 'Average Revenue Per Paid Student,' + this.averagePerPaidStudent.toFixed(3) + '\n\n'
        return overviewString
    }

    get businessUserProfileExportContent(): string {
        let businessUserProfileString = ''
        let businessUserProfile = [
            {
                title: 'BUSINESS USER PROFILE',
                columns: ['DSE YEAR', 'NO. OF STUDENTS'],
                labels: this.userProfileDseYrPieChartConfig.labels,
                series: this.userProfileDseYrSeries
            },
            {
                title: 'BUSINESS USER PROFILE',
                columns: ['BANDING', 'NO. OF STUDENTS'],
                labels: this.userProfileBandingPieCharConfig.labels,
                series: this.userProfileBandingSeries
            }
        ]

        for (const chart of businessUserProfile) {
            businessUserProfileString += this.pieChartDataToCsvContent(chart)
        }
        return businessUserProfileString
    }

    get paymentMethodExportContent(): string {
        return this.pieChartDataToCsvContent({
            title: 'PAYMENT METHOD',
            columns: ['METHOD', 'NO. OF ORDERS'],
            labels: this.paymentMethodPieCharConfig.labels,
            series: this.paymentMethodSeries
        })
    }

    get paymentMethodRevenueExportContent(): string {
        return this.pieChartDataToCsvContent({
            title: 'PAYMENT METHOD REVENUE',
            columns: ['METHOD', 'REVENUE'],
            labels: this.paymentMethodPieCharConfig.labels,
            series: this.paymentMethodRevenueSeries
        })
    }

    get salesDeviceExportContent(): string {
        return this.pieChartDataToCsvContent({
            title: 'SALES DEVICE',
            columns: ['DSE YEAR', 'NO. OF ORDERS'],
            labels: this.salesDevicesPieCharConfig.labels,
            series: this.salesDevicesSeries
        })
    }

    get timelineExportContent(): string {
        let csvContent = ''
        let timelineRevenueRows: string[] = ['TIMELINE']
        let timelineOrdersCountRows: string[] = ['TIMELINE']
        timelineRevenueRows.push('DATE,REVENUE')
        timelineOrdersCountRows.push('DATE,NO. OF ORDERS')
        for (const i in this.timeLineConfigBackup.xaxis!!.categories) {
            const date = this.timeLineConfigBackup.xaxis!!.categories[i]
            timelineRevenueRows.push(date + ',' + this.timeLineRevenueData[0].data[i])
            timelineOrdersCountRows.push(date + ',' + this.timeLineCountData[0].data[i])
        }
        csvContent += timelineRevenueRows.join('\n') + '\n\n' + timelineOrdersCountRows.join('\n') + '\n\n'
        return csvContent
    }

    pieChartDataToCsvContent(pieChartData: { title: string; columns: string[]; labels: string[] | undefined; series: number[] }): string {
        let csvContent = ''
        let rows: string[] = []
        rows.push(pieChartData.title)
        rows.push(pieChartData.columns.join(','))
        for (const i in pieChartData.labels) {
            rows.push(pieChartData.labels[i] + ',' + pieChartData.series[i])
        }
        csvContent += rows.join('\n') + '\n\n'
        return csvContent
    }

    get statisticsExportContent(): string {
        let csvContent = 'OWN TUTOR VS OTHER TUTOR STATISTICS\n,All Tutors,Own Tutors,Other Tutors\n'
        for (const row of this.tutorComparisonTableData) {
            const rowDataArray = [row.title.replace('#', 'No.'), row.all, row.own, row.other]
            csvContent += rowDataArray.join(',') + '\n'
        }
        csvContent += '\n'
        return csvContent
    }

    downloadCsv(csvContent: string, filename: string) {
        // Export.
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    exportOverviewClicked() {
        const csvContent = 'data:text/csv;charset=utf-8,' + this.overviewExportContent
        this.downloadCsv(csvContent, 'business_dashboard_overview.csv')
    }

    exportBusinessUserProfileClicked() {
        const csvContent = 'data:text/csv;charset=utf-8,' + this.businessUserProfileExportContent
        this.downloadCsv(csvContent, 'business_dashboard_user_profile.csv')
    }

    exportPaymentMethodClicked() {
        const csvContent = 'data:text/csv;charset=utf-8,' + this.paymentMethodExportContent
        this.downloadCsv(csvContent, 'business_dashboard_payment_method.csv')
    }

    exportPaymentMethodRevenueClicked() {
        const csvContent = 'data:text/csv;charset=utf-8,' + this.paymentMethodRevenueExportContent
        this.downloadCsv(csvContent, 'business_dashboard_payment_method_revenue.csv')
    }

    exportSalesDeviceClicked() {
        const csvContent = 'data:text/csv;charset=utf-8,' + this.salesDeviceExportContent
        this.downloadCsv(csvContent, 'business_dashboard_sales_device.csv')
    }

    exportTimelineClicked() {
        const csvContent = 'data:text/csv;charset=utf-8,' + this.timelineExportContent
        this.downloadCsv(csvContent, 'business_dashboard_timeline.csv')
    }

    exportStatisticsClicked() {
        const csvContent = 'data:text/csv;charset=utf-8,' + this.statisticsExportContent
        this.downloadCsv(csvContent, 'business_dashboard_statistics.csv')
    }

    exportClicked() {

        // Init csv content.
        let csvContent = 'data:text/csv;charset=utf-8,'

        // Overview.
        csvContent += this.overviewExportContent

        // Revenue.
        for (const chart of this.revenueChartsArray) {
            let rows: string[] = []
            rows.push(chart.title)
            rows.push(chart.title.replace('REVENUE BY ', '') + ',' + chart.series[0].name.toUpperCase())
            for (const i in chart.series[0].data) {
                if (chart.options.xaxis) {
                    // Use quotes to escape commas in course title.
                    rows.push('"' + chart.options.xaxis.categories[i] + '\",' + chart.series[0].data[i])
                }
            }
            csvContent = csvContent + rows.join('\n') + '\n\n'
        }

        // Application source to paid course.
        let applicationSourceRevenueRows: string[] = ['APPLICATION SOURCE TO PAID COURSE']
        let applicationSourceOrdersCountRows: string[] = ['APPLICATION SOURCE TO PAID COURSE']
        applicationSourceRevenueRows.push('MONTH,REVENUE (ORGANIC),REVENUE (TRIAL)')
        applicationSourceOrdersCountRows.push('MONTH,NO. OF ORDERS (ORGANIC),NO. OF ORDERS (TRIAL)')
        for (const monthData of this.applicationSource) {
            applicationSourceRevenueRows.push(monthData.tag + ',' + monthData.organic + ',' + monthData.trial)
            applicationSourceOrdersCountRows.push(monthData.tag + ',' + monthData.organic_count + ',' + monthData.trial_count)
        }
        csvContent = csvContent + applicationSourceRevenueRows.join('\n') + '\n\n'
        csvContent = csvContent + applicationSourceOrdersCountRows.join('\n') + '\n\n'

        // Business user profile.
        csvContent += this.businessUserProfileExportContent

        // Payment method.
        csvContent += this.paymentMethodExportContent

        // Payment method Revenue.
        csvContent += this.paymentMethodRevenueExportContent

        // Sales device.
        csvContent += this.salesDeviceExportContent

        // Timeline.
        csvContent += this.timelineExportContent

        // VS other tutor statistics.
        csvContent += this.statisticsExportContent

        // Export.
        this.downloadCsv(csvContent, 'business_dashboard.csv')
    }
}
